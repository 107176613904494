import axios from '@axios'

const imageFields = ['logo', 'favicon', 'hero_img', 'footer_img']

const loadingData = {
  icon: null,
  title: 'New instance is building',
  subtitle: 'It will take a while',
  showFooter: false,
}
const loadingUpdateData = {
  icon: null,
  title: 'The Instance is updating',
  subtitle: 'It will take a while',
  showFooter: false,
}
const successData = {
  icon: 'CheckCircleIcon',
  iconVariant: 'primary',
  title: 'The instance successfully created',
  subtitle: 'Would you like to add users to this instance?',
  showFooter: true,
}
const successUpdateData = {
  icon: 'CheckCircleIcon',
  iconVariant: 'primary',
  title: 'The instance successfully updated',
  subtitle: '',
  showFooter: false,
}
const errorData = {
  icon: 'AlertCircleIcon',
  iconVariant: 'danger',
  title: 'The instance is not created',
  subtitle: 'Please have another try.',
  showFooter: false,
}
const errorUpdateData = {
  icon: 'AlertCircleIcon',
  iconVariant: 'danger',
  title: 'The instance is not updated',
  subtitle: 'Please have another try.',
  showFooter: false,
}

export default {
  namespaced: true,
  state: {
    instance: null,
    isLoading: false,
    saveInstanceModalData: {
      icon: '',
      title: '',
      subtitle: '',
      showFooter: false,
    },
  },
  getters: {
    getInstance: state => state.instance,
    getIsLoading: state => state.isLoading,
    getSaveInstanceModalData: state => state.saveInstanceModalData,
  },
  mutations: {
    SET_INSTANCE: (state, val) => {
      state.instance = val
    },
    SET_IS_LOADING: (state, val) => {
      state.isLoading = val
    },
    SET_SAVE_INSTANCE_MODAL_DATA: (state, val) => {
      state.saveInstanceModalData = val
    },
    PREPARE_INSTANCE_DATA_FOR_SAVING: state => {
      imageFields.forEach(field => {
        if (!state.instance[field].includes('base64')) {
          // Remove image field if it's not a base64 file
          delete state.instance[field]
        }
      })
    },
  },
  actions: {
    async fetchInstancesList(ctx, queryParams) {
      try {
        return await axios.get('/auth/schools', { params: queryParams })
      } catch (error) {
        return error
      }
    },
    async fetchInstance({ commit }, instanceId) {
      try {
        commit('SET_IS_LOADING', true)
        const response = await axios.get(`/auth/schools/${instanceId}`)
        const instanceData = {
          ...response.data.data.config,
          oauth_providers: response.data.data.oauth_providers,
          report_ids: response.data.data.config.report_ids || [],
          name: response.data.data.name,
          logo: response.data.data.config.logo_url,
          favicon: response.data.data.config.favicon_url,
          hero_img: response.data.data.config.hero_img_url,
          footer_img: response.data.data.config.footer_img_url,
        }
        // Remove member_verification_error_message filed
        delete instanceData.member_verification_error_message

        commit('SET_INSTANCE', instanceData)
        return response
      } catch (error) {
        return error
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async createNewInstance({ state, commit }) {
      try {
        commit('SET_IS_LOADING', true)
        commit('SET_SAVE_INSTANCE_MODAL_DATA', loadingData)
        const response = await axios.post('/auth/schools/', state.instance)

        commit('SET_SAVE_INSTANCE_MODAL_DATA', successData)
        return response
      } catch (error) {
        commit('SET_SAVE_INSTANCE_MODAL_DATA', errorData)
        return error
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async updateInstance({ state, commit }, instanceId) {
      try {
        commit('SET_IS_LOADING', true)
        commit('SET_SAVE_INSTANCE_MODAL_DATA', loadingUpdateData)
        commit('PREPARE_INSTANCE_DATA_FOR_SAVING')
        const response = await axios.put(`/auth/schools/${instanceId}`, state.instance)

        commit('SET_SAVE_INSTANCE_MODAL_DATA', successUpdateData)
        return response
      } catch (error) {
        commit('SET_SAVE_INSTANCE_MODAL_DATA', errorUpdateData)
        return error
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
  },
}
